<template>
    <div>
      <!--  上传表格弹出框  -->
      <el-dialog
        :title="platFormTitle"
        :visible.sync="dialogFormVisible"
        width="60%"
        class="dialog"
        center
      >
        <div class="contents1">
          <el-form
            :model="platForm"
            :rules="rules"
            ref="platForm"
            label-width="auto"
          >
          <el-form-item label="活动名称" prop="name">
              <el-input
                v-model="platForm.name"
                :disabled="true"
                placeholder="请输入活动名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传文件" prop="file">
              <el-upload
                :action="`${BASE.PRO1}/promotion/activity/import`"
                :headers="{ token: token }"
                ref="upload"
                :data="{ id: platForm.id }"
                accept=".xlsx"
                :on-success="successFile"
                :on-error="errorFile"
                :before-remove="beforeRemove"
                :on-exceed="onExceed"
                :on-change="onChange"
                class="upload-demo"
                :multiple="false"
                :limit="1"
                :auto-upload="false"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
            <el-button size="small" type="primary" @click="downloadFile"
              >下载模版</el-button
            >
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitFrom" :loading="submitLoading"
            >确认</el-button
          >
        </span>
      </el-dialog>
    </div>
  </template>
    <script>
  import debounce from "lodash/debounce";
  import { BASE } from "@/api";
  
  export default {
    props: ["id"],
    name: "uploadFile",
    data() {
      return {
        BASE,
        token: sessionStorage.getItem("token"),
        submitLoading: false,
        dialogFormVisible: false, //编辑弹窗
        platFormTitle: "", //弹框title
        fileList: [],
        platForm: {
          file: "",
          id: "",
          name: "",
        },
        rules: {
          file: [{ required: true, message: "请上传文件", trigger: "change" }],
        },
      };
    },
    created() {},
    methods: {
      /**
       * 下载模版
       */
      async downloadFile() {
       this.$api.decoration.activitytemplatefile().then(res=>{
        console.log(res,'下载链接');
        this.download(
        res.data.url,
        "大促商品模版"
      );
       })
       
    },
    download(url1, fileName) {
      let self = this;
      const url = url1;
      let x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      x.onload = function () {
        let url = window.URL.createObjectURL(x.response);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      };
      x.send();
    },
      /**
       * 超出数量处理
       */
      onExceed() {
        this.$message.warning(`抱歉，最多只能上传1份文件`);
      },
      /**
       * 上传操作
       */
      successFile(e) {
        console.log(e, "e====");
        this.submitLoading = false;
        if (e.code == 20000) {
          this.dialogFormVisible = false;
          this.$message({
            type: "success",
            message: "关联成功",
          });
          this.$emit("dialogVisibleChange",''); // 刷新列表
        } else {
          this.tool.message(e.msg, "error");
        }
      },
      /**
       * 上传失败
       */
      errorFile(e) {
        this.platForm.file = "";
        this.$message({
          type: "error",
          message: "上传失败",
        });
      },
      /**
       * 删除文件
       */
      beforeRemove(file, fileList) {
        this.fileList = [];
        this.$refs.upload.clearFiles();
      },
      onChange(file) {
        console.log(file, this.fileList, "fileList==");
        // 可以在这里对文件进行一些验证
        this.fileList.push(file);
      },
      /**
       *关联用户
       */
      addData(e) {
        this.platFormTitle = "导入商品";
        this.submitLoading = false;
        this.platForm = {
            file: "",
          id: e.id,
          name: e.name,
        };
        this.dialogFormVisible = true;
        this.fileList = [];
        this.$nextTick(() => {
          this.$refs.upload.clearFiles();
        });
      },
      /**
       * 提交表单
       */
      submitFrom: debounce(function () {
        console.log(this.fileList, this.$refs.upload, "==");
        if (this.fileList.length == 0) {
          this.tool.message("请上传文件", "error");
          return;
        } else {
          this.submitLoading = true;
          this.$refs.upload.submit(); // 触发上传
        }
      }, 300),
    },
  };
  </script>
    <style scoped lang="scss">
  .contents1 {
    box-sizing: border-box;
    padding: 10px 26px 40px;
  
    .hide {
      /deep/ .el-upload--picture-card {
        display: none;
      }
    }
  }
  </style>
    